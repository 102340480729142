import React, { useState, useMemo } from 'react';
import { Title } from 'components/Texts';
import TabPanel from 'components/TabPanel';
import { colors, Line } from 'globalStyle';
import { Box, Grid } from '@material-ui/core';
import { registerCompanySendOtp } from 'services';
import { sanitizeSingleFields } from 'util/objects';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType } from 'components/Buttons';

import { DataPair } from '../modals/RegisterVerificationModal';
import RegisterErrorModal from '../modals/RegisterErrorModal';
import RegisterMobileHeader from '../RegisterMobileHeader';

const MODALS = Object.freeze({
  VALIDATION: 0,
  ERROR: 'ERROR',
});

export default function RegisterVerificationCompany({
  formValues, onFinish, onError, onBack, onClose, isDesktop, open,
}) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [activeModal, setActiveModal] = useState(MODALS.VALIDATION);
  const sendOTP = useMemo(() => () => {
    const body = sanitizeSingleFields(formValues,
      ['email', 'phoneNumber', 'legalRepEmail']);
    body.documentType = formValues.legalRepDocType;
    body.nit = `${formValues.nit}${formValues.verificationDigit}`;
    setLoading(true);
    registerCompanySendOtp(body)
      .then(() => {
        onFinish();
      })
      .catch((v) => {
        setError(v.messageFormat);
        setActiveModal(MODALS.ERROR);
        if (onError) {
          onError(v);
        }
      })
      .finally(() => setLoading(false));
  }, [formValues]);
  return (
    <>
      <TabPanel value={activeModal} index={MODALS.VALIDATION}>
        <CustomDialog
          open={open}
          fullSizeNavBar={!isDesktop}
          width={isDesktop && '615px'}
          onBack={isDesktop && onBack}
          onClose={isDesktop && onClose}
          pt={isDesktop ? '24px' : '0px'}
          px={isDesktop ? '60px' : '35px'}
          height={isDesktop ? '530px' : '466px'}
          header={isDesktop && (
            <Title size={24} width="fit-content">
              Verifica tus datos
            </Title>
          )}
        >
          <Grid container direction="column" alignItems="center">
            {!isDesktop && (
              <RegisterMobileHeader
                title="Persona jurídica"
                subtitle="Verificación de datos"
              />
            )}
            <Title
              isCenter
              color={colors.primary}
              size={isDesktop ? 18 : 16}
            >
              Empresa
              {` ${formValues.name}`}
            </Title>
            <Box
              display="flex"
              component="ul"
              flexWrap="wrap"
              justifyContent="center"
              style={{ listStyle: 'disc' }}
              marginTop={isDesktop ? '10px' : '12px'}
              marginBottom={isDesktop ? '30px' : '10px'}
            >
              <DataPair
                title="NIT:"
                variant="mobileList"
                isDesktop={isDesktop}
                value={`${formValues.nit}-${formValues.verificationDigit}`}
              />
              <DataPair
                variant="mobileList"
                isDesktop={isDesktop}
                value={formValues.email}
                title="Correo electrónico:"
              />
              <DataPair
                variant="mobileList"
                isDesktop={isDesktop}
                value={formValues.phoneNumber}
                title="Número de teléfono:"
              />
            </Box>
            <Line
              isCenter
              height="0px"
              thickness={!isDesktop && '1px'}
              width={isDesktop ? '456px' : '90%'}
              color={isDesktop ? colors.primary : colors.GRAY_11}
            />
            <Title
              isCenter
              color={colors.primary}
              mt={isDesktop ? 20 : 12}
              size={isDesktop ? 18 : 16}
            >
              Representante legal
            </Title>
            <Box
              display="flex"
              component="ul"
              flexWrap="wrap"
              justifyContent="center"
              style={{ listStyle: 'disc' }}
              marginBottom={!isDesktop && '9px'}
              marginTop={isDesktop ? '10px' : '16px'}
            >
              <DataPair
                variant="mobileList"
                isDesktop={isDesktop}
                value={formValues.legalRepDni}
                title="Número de documento:"
              />
              <DataPair
                variant="mobileList"
                title="Correo electrónico:"
                isDesktop={isDesktop}
                value={formValues.legalRepEmail}
              />
              <DataPair
                variant="mobileList"
                isDesktop={isDesktop}
                value={formValues.legalRepPhoneNumber}
                title="Número de teléfono:"
              />
            </Box>
          </Grid>
          <Box
            display="flex"
            flexWrap="wrap"
            marginTop="37px"
            gridRowGap="20px"
            gridColumnGap="34px"
            justifyContent="center"
            flexDirection={isDesktop ? 'row' : 'column-reverse'}
          >
            <Box
              width={isDesktop ? '220px' : '100%'}
            >
              <Hollow
                size={14}
                isSubmit
                fullWidth
                onClick={onBack}
                onColorType={hollowColorType.WhiteBorder}
              >
                No, modificar
              </Hollow>
            </Box>
            <Box
              width={isDesktop ? '220px' : '100%'}
            >
              <Hollow
                size={14}
                fullWidth
                onClick={sendOTP}
                isLoading={loading}
                onColorType={hollowColorType.primary}
              >
                Sí, continuar
              </Hollow>
            </Box>
          </Box>
        </CustomDialog>
      </TabPanel>
      <TabPanel value={activeModal} index={MODALS.ERROR}>
        <RegisterErrorModal
          open
          error={error}
          onClose={onClose}
          isDesktop={isDesktop}
        />
      </TabPanel>
    </>
  );
}
