import React, { useEffect, useState } from 'react';
import { routes } from 'routes';
import { userType } from 'types';
import { colors } from 'globalStyle';
import Skeleton from 'react-loading-skeleton';
import { Box, Grid } from '@material-ui/core';
import { Text, Title } from 'components/Texts';
import BackIcon from 'assets/icons/back-white.svg';
import RedCircle from 'assets/icons/circle-red.svg';
import RefreshIcon from 'assets/icons/refresh-white.svg';
import ModalContent from 'components/Modals/ModalContent';
import PrimaryCircle from 'assets/icons/circle-primary.svg';
import { useRequestQR, useRequestQRCompany } from 'services/user/other/qr';
import { Ghost, Hollow, hollowColorType } from 'components/Buttons';

const buttonStyle = Object.freeze({
  style: {
    cursor: 'pointer',
  },
});

const QR_LIFE = 300000;

export default function RegisterQR({
  onClose, onBack, text, type, pt, logout,
}) {
  const [qr, setQR] = useState();
  const [getQR, setGetQR] = useState();
  const [error, setError] = useState(false);
  const [timedOut, setTimedOut] = useState(false);
  const reqQR = () => setGetQR((curr) => !curr);
  useEffect(() => {
    let timer;
    const requestQr = type === userType.USER ? useRequestQR : useRequestQRCompany;
    requestQr(`https://${document.location.host}${routes.dataCompletion.idVerification}`)
      .then(({ data }) => {
        setQR(`data:image/png;base64,${data.base64}`);
        setTimedOut(false);
        timer = setInterval(() => {
          setTimedOut(true);
        }, QR_LIFE);
      })
      .catch(() => {
        setError(true);
      });
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [getQR, type]);
  return (
    <ModalContent
      width="615px"
      height="530px"
      onBack={onBack}
      pt={pt || '30px'}
      onClose={onClose}
    >
      <Grid container direction="column" alignItems="center" style={{ position: 'relative' }}>
        <Title size={20} isCenter>
          Escanea nuestro código QR
        </Title>
        <Text size={16} width="454px" isCenter mt={8} mb={32}>
          {text || (
            <>
              Para realizar la validación de identidad usa
              la cámara o abre el lector de código QR de tu celular.
            </>
          )}
        </Text>
        {qr
          ? (
            <Box
              width="201px"
              component="img"
              height="209.826px"
              src={qr || 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
              style={{
                opacity: timedOut ? 0.25 : 1,
              }}
            />
          )
          : <Skeleton height="209.826px" width="201px" />}
        {timedOut && (
          <>
            <Box
              top="114px"
              component="img"
              onClick={reqQR}
              position="absolute"
              src={PrimaryCircle}
              {...buttonStyle}
            />
            <Box
              top="160px"
              component="img"
              onClick={reqQR}
              src={RefreshIcon}
              position="absolute"
              {...buttonStyle}
            />
            <Box
              top="215px"
              onClick={reqQR}
              position="absolute"
              {...buttonStyle}
            >
              <Text size={12} isCenter width="123px" color={colors.WHITE}>
                Click para recargar el código QR
              </Text>
            </Box>
          </>
        )}
        {error && (
          <>
            <Box
              top="114px"
              component="img"
              src={RedCircle}
              onClick={onBack}
              {...buttonStyle}
              position="absolute"
            />
            <Box
              top="160px"
              component="img"
              src={BackIcon}
              onClick={onBack}
              {...buttonStyle}
              position="absolute"
            />
            <Box
              top="195px"
              onClick={onBack}
              {...buttonStyle}
              position="absolute"
            >
              <Text size={12} isCenter width="123px" color={colors.WHITE}>
                El código QR no está disponible,
                <br />
                por favor vuelve al paso anterior
              </Text>
            </Box>
          </>
        )}
        {logout && (
          <Box
            width="220px"
            marginTop="30px"
            marginBottom="10px"
          >
            <Hollow
              isCenter
              isSubmit
              underline
              fullWidth
              size={14}
              padding="12px"
              onClick={logout}
              onColorType={hollowColorType.primary}
            >
              Cerrar sesión
            </Hollow>
          </Box>
        )}
        <Ghost
          isCenter
          size={12}
          underline
          type="button"
          width="fit-content"
          color={colors.GRAY_11}
          href={routes.message.contactUs}
        >
          ¿Necesitas ayuda?
        </Ghost>
      </Grid>
    </ModalContent>
  );
}
