import React, { useState } from 'react';
import { routes } from 'routes';
import { userType } from 'types';
import { useLocation } from 'wouter';
import { useMediaQuery } from 'hooks';
import { Modal } from 'components/Modals';
import TabPanel from 'components/TabPanel';

import RegisterQR from './components/RegisterQR';
import RegisterType from './components/RegisterType';
import RegisterForm from './components/RegisterForm';
import RegisterSteps from './components/steps/RegisterSteps';
import RegisterCompletionStep1 from './components/RegisterCompletionStep1';
import RegisterFormCompany from './components/company/RegisterFormCompany';
import RegisterCompletionStep1Qualified from './components/RegisterCompletionStep1Qualified';

export const REGISTER_STEPS = Object.freeze({
  INITIAL: 0,
  USER_NEXT_STEP: 3,
});

export const REGISTER_NEXT_STEP = Object.freeze({
  PHONE: 1,
  FINANCIAL: 2,
});

export const Register = ({
  isOpen,
  onClick,
  login,
  initialStep = REGISTER_STEPS.INITIAL,
  stepperProps,
}) => {
  const [, setLocation] = useLocation();
  const [value, setValue] = useState(initialStep);
  const back = () => setValue((currValue) => currValue - 1);
  const nextStep = () => setValue((currValue) => currValue + 1);
  const [clientType, setClientType] = useState(userType.USER);
  const [clientClass, _setClientClass] = useState();
  const setClientClass = (client) => {
    _setClientClass(client);
    nextStep();
  };
  const chooseClientType = (type) => {
    setClientType(type);
    nextStep();
  };
  const isDesktop = useMediaQuery(1023);
  const goToNextStep = () => {
    if (isDesktop) {
      if (clientType === userType.USER) {
        nextStep();
      } else {
        onClick();
      }
    } else {
      setLocation(routes.dataCompletion.idVerification);
    }
  };

  return (
    <Modal
      wrap
      top="0px"
      isBackground
      zIndex={1001}
      isOpen={isOpen}
      handleClick={onClick}
    >
      <TabPanel value={value} index={0}>
        <RegisterType
          isDesktop={isDesktop}
          onClose={onClick}
          onFinish={chooseClientType}
        />
      </TabPanel>
      {clientType === userType.USER && (
        <>
          <TabPanel value={value} index={1}>
            <RegisterForm
              onBack={back}
              onClose={onClick}
              isDesktop={isDesktop}
              onFinish={setClientClass}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            {clientClass === 'QUALIFIED'
              ? (
                <RegisterCompletionStep1Qualified
                  onClose={onClick}
                  onFinish={nextStep}
                  isDesktop={isDesktop}
                />
              )
              : (
                <RegisterCompletionStep1
                  onClose={onClick}
                  onFinish={nextStep}
                  isDesktop={isDesktop}
                />
              )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            <RegisterSteps
              onClose={onClick}
              isDesktop={isDesktop}
              step={(stepperProps && stepperProps.nextStep) || 1}
              onFinish={(stepperProps && stepperProps.onNext) || goToNextStep}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <RegisterQR
              onBack={back}
              onClose={onClick}
              type={clientType}
            />
          </TabPanel>
        </>
      )}
      {clientType === userType.COMPANY && (
        <>
          <TabPanel value={value} index={1}>
            <RegisterFormCompany
              login={login}
              onBack={back}
              onClose={onClick}
              isDesktop={isDesktop}
              onFinish={goToNextStep}
            />
          </TabPanel>
        </>
      )}
    </Modal>
  );
};

// export const Register = ({ isOpen, onClick }) => (
//   <NewUserRegister open={isOpen} handleClose={onClick} />

// );
