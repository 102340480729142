/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */ import React from 'react';
import Box from '@material-ui/core/Box';
import { Link, useLocation } from 'wouter';
import {
  colors, Div, weight, Figure,
  NAVBAR_HEIGHT,
} from 'globalStyle';
import { getContrastTextColor } from 'util/contrast';
import { Ghost, Hollow } from 'components/Buttons';
import { routes } from 'routes';
import logo from 'assets/logo.svg';
import { ReactComponent as Headphones } from 'assets/icons/headphones.svg';
import { useDispatch } from 'react-redux';
import { closePopUp, openPopUp } from 'store/popup';
import { useUser } from 'hooks';
import styled from 'styled-components';
import { userType } from 'types';
import { VerticalMenu } from './components/VerticalMenu';

export const NavbarDesktop = ({ customLogo, buttonsColor }) => {
  const dispatch = useDispatch();
  const {
    isLogin, logout, type, user,
  } = useUser();
  const name = type === userType.USER
    ? `${user?.info?.firstName} ${user?.info?.firstLastName}`
    : user?.info?.name;

  const handleLogout = () => {
    logout();
    dispatch(closePopUp());
  };
  const [location] = useLocation();

  const Active = ({ isActive }) => (
    <Div
      width="6px"
      height="6px"
      backgroundColor={isActive && colors.primary}
      style={{ borderRadius: '3px', position: 'absolute', bottom: '15px' }}
    />
  );
  const Buttons = () => {
    if (!isLogin) {
      return (
        <>
          <RegisterButton
            isSubmit
            onClick={() => dispatch(openPopUp({ type: 'register' }))}
            padding="7px 0"
            size="12px"
            onColorType="white"
            color={colors.primary}
            customColor={buttonsColor}
            style={{ borderRadius: '40px', width: '140px', height: '35px' }}
          >
            Regístrate
          </RegisterButton>

          <LoginButton
            isSubmit
            onColorType="primary"
            onClick={() => dispatch(openPopUp({ type: 'login' }))}
            padding="7px 0"
            size="12px"
            customColor={buttonsColor}
            style={{ borderRadius: '40px', width: '140px', height: '35px' }}
          >
            Inicia sesión
          </LoginButton>
        </>
      );
    }
    return (
      <ContainerItems>
        <VerticalMenu
          names={name}
          hasCompany={user.hasCompany}
          type={type}
          handleClick={() => handleLogout()}
        />
        <Active isActive={location.includes('/perfil')} />
      </ContainerItems>
    );
  };
  return (
    <Container>
      {customLogo || (
        <Link href={routes.home}>
          <Figure
            height="70px"
            width="120px"
            style={{ position: 'relative', cursor: 'pointer' }}
          >
            <img src={logo} alt="logo" />
          </Figure>
        </Link>
      )}

      <Div
        isFlex
        justify="flex-end"
        width="60%"
        style={{ gap: '20px' }}
        height="40px"
      >
        {((isLogin && type === userType.USER) || (!isLogin)) && (
          <ContainerItems>
            <StyledGhost
              active
              size={15}
              weight={weight.MEDIUM}
              customColor={buttonsColor}
              color={
                buttonsColor
                  ? getContrastTextColor(buttonsColor, { defaultDark: buttonsColor })
                  : colors.primary
                }
              href={routes.project.allProjects}
            >
              Invertir
            </StyledGhost>
            <Active isActive={location === routes.project.allProjects} />
          </ContainerItems>
        )}

        <ContainerItems>
          <StyledGhost
            active
            href={routes.aboutUs}
            weight={weight.MEDIUM}
            color={colors.GRAY_1}
            customColor={buttonsColor}
            size={15}
          >
            Acerca de nosotros
          </StyledGhost>
          <Active isActive={location === routes.aboutUs} />
        </ContainerItems>

        <ContainerItems>
          <StyledGhost
            active
            href={routes.financeProject}
            customColor={buttonsColor}
            weight={weight.MEDIUM}
            color={colors.GRAY_1}
            size={15}
          >
            Postula tu proyecto
          </StyledGhost>
          <Active isActive={location === routes.financeProject} />
        </ContainerItems>

        <Buttons />
        <Link href={routes.frequentQuestions}>
          <ContactButton color={buttonsColor}>
            <Headphones
              color={buttonsColor
                ? getContrastTextColor(buttonsColor, { defaultDark: buttonsColor })
                : colors.primary}
            />
          </ContactButton>
        </Link>
      </Div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  height: ${NAVBAR_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${colors.WHITE};
  border-radius: 0px 0px 25px 25px;
  box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.34);
`;

const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ContactButton = styled.div`
  width: 35px;
  height: 35px;
  background-color: ${({ color }) => (color ? getContrastTextColor(color, { defaultLight: color, reverse: true }) : colors.WHITE)};
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.34);
`;

const RegisterButton = styled(Hollow)`
  ${({ customColor }) => `
    color: ${getContrastTextColor(customColor, { defaultDark: customColor })} !important;
    border: 1px solid ${customColor} !important;
    &:hover {
      color: ${colors.WHITE} !important;
      background: ${customColor}aa !important;
      border: 1px solid ${customColor}aa !important;
    }
  `}
`;

const LoginButton = styled(Hollow)`
  ${({ customColor }) => customColor && `
    background: ${customColor} !important;
    color: ${getContrastTextColor(customColor)} !important;
    border: 1px solid ${customColor} !important;
    &:hover {
      background: ${customColor}aa !important;
      border: 1px solid ${customColor}aa !important;
    }
  `}
`;

const StyledGhost = styled(Ghost)`
  ${({ customColor }) => customColor && `
    &:hover {
      color: ${customColor} !important;
    }
  `}
`;
