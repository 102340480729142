import React from 'react';
import { userType } from 'types';
import { Box } from '@material-ui/core';
import { Title } from 'components/Texts';
import { Line, colors } from 'globalStyle';
import Container from 'components/Container';

export const CLIENT_TYPE = Object.freeze({
  INVESTOR: 0,
  COMPANY: 1,
});

export default function MobileHeader({ clientType }) {
  return (
    <Container mt={-19} notClickable>
      <Title
        mb={3}
        size={16}
        color={colors.primary}
      >
        {(clientType === CLIENT_TYPE.INVESTOR || clientType === userType.USER) ? 'Persona natural' : 'Persona jurídica'}
      </Title>
      <Line width="100%" color={colors.primary} mb={61} />
      <Box marginBottom="61px" />
    </Container>
  );
}
